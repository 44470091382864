import {Component, HostListener} from '@angular/core';
import {SidebarService} from '../../shared/services/sidebar.service';
import {Store} from '@ngxs/store';
import {AppState} from '../../store/app/app.state';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {AuthenticationService} from '../auth/authentication.service';
import {ConfigurationService} from '../config/configuration.service';
import {EnvironmentEnum} from 'src/app/enums/environment.enum';
import {ProfileState} from 'src/app/store/profile/profile.state';
import {LanguageService} from 'src/app/core/i18n/language.service';
import {AvailableLang} from '@discover-swiss/models';
import {ConnectedPosition} from '@angular/cdk/overlay';
import {CheckoutAppService} from 'src/app/shared/services/checkout-app.service';
import {WindowSizeService} from 'src/app/shared/services/window-size.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    public readonly nameFirstLetter$ = this.store.select(ProfileState.profile).pipe(
        map((profile) => profile?.givenName?.charAt(0) ?? ''),
    );
    public environmentEnum = EnvironmentEnum;
    public currentEnv = this.config.getCurrentEnv();

    public authorized$: Observable<boolean> = this.store
        .select(AppState.isAuthenticated)
        .pipe(startWith(false));

    public readonly language$ = this.languageService.languageChange$;
    public readonly languages = this.languageService.availableLang;
    public showLangDropdown: boolean = false;
    public showLogoutDropdown: boolean = false;

    public readonly centerPosition: ConnectedPosition[] = [
        {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
        }
    ];

    public readonly leftPosition: ConnectedPosition[] = [
        {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: 30,
        }
    ];

    public readonly isMobile$ = this.windowSizeService.isMobile$;
    public readonly dynamicPosition$ = this.isMobile$.pipe(
        map((isMobile) => isMobile ? this.leftPosition : this.centerPosition),
    );

    public readonly imageSrc$ = this.language$.pipe(
        distinctUntilChanged(),
        map((lang) => `/assets/images/logo/${lang}-logo.svg`),
    );

    

    constructor(
        private store: Store,
        private authService: AuthenticationService,
        private sidebar: SidebarService,
        private config: ConfigurationService,
        private languageService: LanguageService,
        private checkoutAppSrv: CheckoutAppService,
        private windowSizeService: WindowSizeService,
    ) { }

    public login(): void {
        this.authService.login();
    }

    public logout(): void {
        this.authService.logout();
    }

    public onMenu(): void {
        this.sidebar.openMenu('menu');
    }

    public onSearch(): void {
        this.sidebar.openMenu('search');
    }

    public selectLang(lang: string): void {
        this.languageService.use(lang as AvailableLang);
    }

    public switchLangDropdown(): void {
        this.showLangDropdown = !this.showLangDropdown;
        this.closeLogoutDropdown();
    }

    public switchLogoutDropdown(): void {
        this.showLogoutDropdown = !this.showLogoutDropdown;
        this.closeLangDropdown();
    }

    public closeLangDropdown(): void {
        this.showLangDropdown = false;
    }

    public closeLogoutDropdown(): void {
        this.showLogoutDropdown = false;
    }

    public onHomeClick(): void {
        this.checkoutAppSrv.closeCheckout();
    }

    @HostListener('document:click')
    public onDocumentClick(): void {
        this.closeLangDropdown();
        this.closeLogoutDropdown();
    }
}
