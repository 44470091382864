import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxsModule} from '@ngxs/store';
import {AppState} from './store/app/app.state';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {HeaderComponent} from './core/header/header.component';
import {FooterComponent} from './core/footer/footer.component';
import {environment} from '../environments/environment';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {
    HTTP_INTERCEPTORS,
    HttpBackend,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {SubscriptionKeyInterceptor} from 'src/app/core/auth/subscription-key-interceptor';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {DatePipe, registerLocaleData} from '@angular/common';
import {MsalApplicationModule} from 'src/app/core/auth/msal-application.module';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TicketsState} from './store/e-tickets/tickets.state';
import {OrdersState} from './store/orders/orders.state';
import {ProfileState} from './store/profile/profile.state';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {RequestPartnerInterceptor} from './interceptors/request-partner.interceptor';
import {TicketsItemState} from './store/e-tickets/tickets-item/tickets-item.state';
import {PartyItemState} from 'src/app/store/parties/party-item/party-item.state';
import {PartiesState} from 'src/app/store/parties/parties.state';
import {ActivitiesState} from './store/activities/activities.state';
import {PartnerDataItemsState} from 'src/app/store/partner-data/partner-data-items.state';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SharedDataModule} from './modules/shared-data/shared-data.module';
import {SupportState} from './store/support/support.state';
import {OrdersItemState} from './store/orders/orders-item/orders-item.state';
import {KnowledgeBaseItemsState} from 'src/app/store/knowledge-base/kb-items.state';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeItExtra from '@angular/common/locales/extra/it';
import {
    CONFIG_SERVICE_TOKEN,
    LANGUAGE_SERVICE_TOKEN,
    TRANSLATE_SERVICE_TOKEN,
} from '@discover-swiss/shared';
import {LanguageService} from 'src/app/core/i18n/language.service';
import {SelectLanguageModule} from './core/select-language/select-language.module';
import {MsalRedirectComponent} from '@azure/msal-angular';
import {CustomDropdownModule} from 'src/app/shared/components/custom-dropdown/custom-dropdown.module';
import {MenuModule} from 'src/app/core/menu/menu.module';
import {SupportIssuesState} from 'src/app/store/support-issues/support-issues.state';
import {AcceptLangInterceptor} from 'src/app/interceptors/accept-lang.interceptor';
import {LoaderModule} from 'src/app/shared/components/loader/loader.module';
import {AppInsightsModule} from 'src/app/core/app-insights/app-insights.module';
import {FailedComponent} from 'src/app/core/failed/failed.component';
import {CheckoutWcWrapperComponent} from 'src/app/checkout-wc-wrapper/checkout-wc-wrapper.component';
import {SecondHeaderComponent} from './core/second-header/second-header.component';
import {MatMenuModule} from '@angular/material/menu';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatDividerModule} from '@angular/material/divider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
registerLocaleData(localeIt, 'it-IT', localeItExtra);

export const httpLoaderFactory = (http: HttpBackend): TranslateLoader => {
    return new TranslateHttpLoader(new HttpClient(http));
};

@NgModule({
    declarations: [AppComponent, HeaderComponent, FooterComponent, FailedComponent],
    bootstrap: [AppComponent, MsalRedirectComponent],
    imports: [
        BrowserModule,
        SecondHeaderComponent,
        MsalApplicationModule.forRoot(),
        AppRoutingModule,
        NgxsModule.forRoot(
            [
                AppState,
                TicketsState,
                TicketsItemState,
                OrdersState,
                OrdersItemState,
                ProfileState,
                PartyItemState,
                PartiesState,
                ActivitiesState,
                PartnerDataItemsState,
                SupportState,
                SupportIssuesState,
                KnowledgeBaseItemsState,
            ],
            {
                developmentMode: !environment.production,
            },
        ),
        NgxsRouterPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatSidenavModule,
        SharedDataModule,
        SelectLanguageModule,
        CustomDropdownModule,
        MenuModule,
        LoaderModule,
        AppInsightsModule,
        CheckoutWcWrapperComponent,
        MatMenuModule,
        OverlayModule,
        MatDividerModule,
        MatSnackBarModule,
        MatDialogModule,
    ],
    providers: [
        /* {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
        }, */
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestPartnerInterceptor,
            multi: true,
            deps: [ConfigurationService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SubscriptionKeyInterceptor,
            multi: true,
            deps: [ConfigurationService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AcceptLangInterceptor,
            multi: true,
            deps: [LanguageService],
        },
        DatePipe,
        {
            provide: TRANSLATE_SERVICE_TOKEN,
            useExisting: TranslateService,
        },
        {
            provide: CONFIG_SERVICE_TOKEN,
            useExisting: ConfigurationService,
        },
        {
            provide: LANGUAGE_SERVICE_TOKEN,
            useExisting: LanguageService,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
