import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Subscription, filter} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GreetingMessageService implements OnDestroy {
    private readonly greetingSrc$ = new BehaviorSubject<string | null>(null);
    public readonly greeting$ = this.greetingSrc$.asObservable();

    private subscriptions: Subscription;

    constructor(private router: Router) {
        this.subscriptions = router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
        ).subscribe(() => {
            this.clear();
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions?.unsubscribe();
    }

    public setGreeting(greeting: string): void {
        this.greetingSrc$.next(greeting);
    }

    public clear(): void {
        this.greetingSrc$.next(null);
    }
}