import {ErrorHandler, Inject, NgModule} from '@angular/core';
import {ApplicationinsightsAngularpluginErrorService} from '@microsoft/applicationinsights-angularplugin-js';
import {BehaviorSubject} from 'rxjs';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {APP_INIT_LOCAL_STORAGE_DATA, ID_TOKEN_HINT_INJECTION_TOKEN} from 'src/app/shared/injection-token/injection-tokens';

export class ExtendedErrorHandlingPlugin extends ApplicationinsightsAngularpluginErrorService {
    private readonly msalErrorSrc$ = new BehaviorSubject<any>(null);
    public readonly msalError$ = this.msalErrorSrc$.asObservable();

    public handleError(error: Error): void {
        if (error.message.startsWith('server_error: AADB2C:')) {
            this.msalErrorSrc$.next(error);
        }
        super.handleError(error);
    }
}


@NgModule({
    providers: [
        AppInsightsService,
        {
            provide: ErrorHandler,
            useClass: ExtendedErrorHandlingPlugin,
        },
    ],
})
export class AppInsightsModule {
    constructor(
        private appInsightsService: AppInsightsService,
        @Inject(ID_TOKEN_HINT_INJECTION_TOKEN) private idTokenHint: string,
        @Inject(APP_INIT_LOCAL_STORAGE_DATA) private localStorageData: any,
    ) {
        this.appInsightsService.trackTrace({
            message: `ID_TOKEN_HINT: app initialized ${!!this.idTokenHint ? 'with' : 'without'} idTokenHint`,
            properties: {idTokenHint: this.idTokenHint},
        });
        this.appInsightsService.trackTrace({
            message: `APP_INIT_LOCAL_STORAGE_DATA: app initialized with local storage data: `,
            properties: {localStorageData},
        });
    }
}
