import {AsyncPipe, NgIf, NgTemplateOutlet} from '@angular/common';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {Select} from '@ngxs/store';
import {Observable, asyncScheduler, combineLatestWith, distinctUntilChanged, map, observeOn, shareReplay, startWith, take} from 'rxjs';
import {AuthenticationService} from 'src/app/core/auth/authentication.service';
import {ShoppingCartDropdownComponent} from 'src/app/core/second-header/shopping-cart-dropdown/shopping-cart-dropdown.component';
import {OrderStatusEnum} from 'src/app/enums/order-status.enum';
import {ViewTypeEnum} from 'src/app/enums/view-type.enum';
import {IProfile} from 'src/app/interfaces/profile';
import {isShowShoppingCart} from 'src/app/shared/helpers/show-shopping-cart.helper';
import {DS_VIEW_TYPE_INJECTION_TOKEN} from 'src/app/shared/injection-token/injection-tokens';
import {CheckoutAppService} from 'src/app/shared/services/checkout-app.service';
import {CheckoutOrderService} from 'src/app/shared/services/checkout-order.service';
import {GreetingMessageService} from 'src/app/shared/services/greeting-message.service';
import {MainPageService} from 'src/app/shared/services/main-page.service';
import {WindowSizeService} from 'src/app/shared/services/window-size.service';
import {ProfileState} from 'src/app/store/profile/profile.state';

@Component({
    selector: 'app-second-header',
    templateUrl: './second-header.component.html',
    styleUrls: ['./second-header.component.scss'],
    standalone: true,
    imports: [
        TranslateModule,
        AsyncPipe,
        NgIf,
        NgTemplateOutlet,
        ShoppingCartDropdownComponent,
    ],
})
export class SecondHeaderComponent implements OnInit {
    @Input() public showGreeting: boolean = false;
    @Select(ProfileState.profile) public profile$: Observable<IProfile>;
    public name$: Observable<string>;

    get loggedIn(): boolean {
        return this.authService.loggedIn;
    };

    public readonly homePage$ = this.mainPageService.isPageFn$('home');
    public readonly isMobile$ = this.windowSizeService.isMobile$;
    public readonly isWebView = this.viewType === ViewTypeEnum.EMBEDDED;
    public readonly landingPage$ = this.mainPageService.isPageFn$('landing');

    public readonly showMobileShoppingCart$ = this.homePage$.pipe(
        combineLatestWith(
            this.landingPage$,
            this.isMobile$,
            this.checkoutOrderService.state$.pipe(
                map((state) => isShowShoppingCart(state.data)),
            ),
        ),
        map(([homePage, landingPage, isMobile, cartNonEmpty]) => {
            return (homePage || landingPage) && isMobile && cartNonEmpty;
        }),
        distinctUntilChanged(),
        observeOn(asyncScheduler),
        startWith(false),
        shareReplay({refCount: true, bufferSize: 1}),
    );

    public readonly showBackButton$ = this.isMobile$.pipe(
        combineLatestWith(this.homePage$, this.landingPage$),
        map(([, landingPage, homePage]) => {
            return !((homePage || landingPage));
        }),
        distinctUntilChanged(),
        startWith(false),
    )

    constructor(
        private readonly greetingService: GreetingMessageService,
        private readonly authService: AuthenticationService,
        private readonly router: Router,
        private readonly mainPageService: MainPageService,
        private readonly windowSizeService: WindowSizeService,
        private readonly checkoutAppService: CheckoutAppService,
        private readonly checkoutOrderService: CheckoutOrderService,
        @Inject(DS_VIEW_TYPE_INJECTION_TOKEN) private readonly viewType: string | null,
    ) { }

    public ngOnInit(): void {
        this.name$ = this.profile$.pipe(
            map((profile) => profile?.givenName)
        );
    }

    public back(): void {
        this.checkoutAppService.opened$.pipe(
            take(1),
        ).subscribe((opened) => {
            if (opened) {
                this.closeCheckout();
            } else {
                this.router.navigate(['..']);
            }
        });
    }

    private closeCheckout(): void {
        this.checkoutAppService.closeCheckout();
    }
}
