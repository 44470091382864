<a href="https://www.youthhostel.ch/" target="_blank" rel="noopener noreferrer">
    <div class="header__logo" >
        <img [src]="imageSrc$ | async" alt="logo">
    </div>
</a>
<nav class="header__menu">
    <ul>
        <li class="header__menu__item" (click)="switchLangDropdown();$event.preventDefault();$event.stopPropagation()" cdkOverlayOrigin #langDropdownTrigger="cdkOverlayOrigin">
            <i class="far fa-globe"></i>
            <span>{{language$ | async | translate}}</span>
        </li>
        <li class="header__menu__item">
            <a [routerLink]="'/'" (click)="onHomeClick()">
                <i class="fa-kit fa-home-uh"></i>
                <span>{{'home' | translate}}</span>
            </a>
        </li>
        <li class="header__menu__item" *ngIf="authorized$ | async; else unauthorized" (click)="switchLogoutDropdown();$event.preventDefault();$event.stopPropagation()" cdkOverlayOrigin #logoutDropdownTrigger="cdkOverlayOrigin">
            <span class="login-icon-wrapper">
                <!-- <i class="far fa-user"></i> -->
                <i class="circle"></i>
                <i class="login-icon">
                    {{nameFirstLetter$ | async}}
                </i>
            </span>
            <span>{{'logout' | translate}}</span>
            <ng-template cdkConnectedOverlay [cdkConnectedOverlayPositions]="dynamicPosition$ | async"  [cdkConnectedOverlayOrigin]="logoutDropdownTrigger" [cdkConnectedOverlayOpen]="showLogoutDropdown">
                <div class="lang-list-wrapper">
                    <div class="lang-list" [class.move-marker]="isMobile$ | async">
                        <button (click)="logout()">{{'logout' | translate}}</button>
                    </div>
                </div>
            </ng-template>
            
        </li>
    </ul>
</nav>

<ng-template #unauthorized>
    <li class="header__menu__item" (click)="login()">
        <i class="far fa-user"></i>
        <span>{{'login' | translate}}</span>
    </li>
</ng-template>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayPositions]="centerPosition"  [cdkConnectedOverlayOrigin]="langDropdownTrigger" [cdkConnectedOverlayOpen]="showLangDropdown">
    <div class="lang-list-wrapper">
        <div class="lang-list">
            <button *ngFor="let lang of languages" (click)="selectLang(lang)">{{lang | translate}}</button>
        </div>
    </div>
</ng-template>