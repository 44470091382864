import {AsyncPipe, NgIf} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, Component} from '@angular/core';
import {CheckoutCoreRegistererService} from 'src/app/shared/services/checkout-core/checkout-core-registerer.service';

@Component({
    selector: 'app-checkout-wc-wrapper',
    templateUrl: './checkout-wc-wrapper.component.html',
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CheckoutWcWrapperComponent {
    public readonly coreWebComponentPayload$ = this.checkoutCoreService.coreWebComponentPayload$;

    constructor(
        private checkoutCoreService: CheckoutCoreRegistererService,
    ) { }
}
