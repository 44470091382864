<ng-container *ngIf="(isRedirectPage$ | async) === false">
    <header [class.home-header]="homePage$ | async" *ngIf="isWebView === false"></header>
    <app-second-header *ngIf="isWebView === false && (landingPage$ | async) === false" [showGreeting]="showGreetings$ | async"></app-second-header>
    <section class="page">
        <ng-container *ngIf="(isAppLoading$ | async) === false; else globalLoader">
            <router-outlet></router-outlet>
        </ng-container>
    </section>
    <footer *ngIf="isWebView === false"></footer>
    
    <ng-template #globalLoader>
        <app-loader class="global-loader"></app-loader>
    </ng-template>
</ng-container>

<app-checkout-wc-wrapper></app-checkout-wc-wrapper>

<router-outlet name="payment"></router-outlet>